<template>
  <div>
    <div
      v-if="!validToPay"
    >
      <p class="error help is-danger">
        {{ missingBillingItem }} is required
      </p>
    </div>
    <div
      v-if="coveredByCredit"
      :class="[depositHasPackage ? '' : 'disabled']"
    >
      <div
        class="expandable_content review-payment"
      >
        <p
          id="checkoutError"
          :class="[errs == null ? '' : 'errors']"
          @click="handleError()"
        >
          {{ errs }}
        </p>
      </div>
    </div>
    <form
      v-else
      id="payment-form"
      :class="validToPay ? '' : 'disabled'"
    >
      <!-- <div id="link-authentication-element"> -->
      <!--Stripe.js injects the Link Authentication Element-->
      <!-- </div> -->
      <div id="payment-element">
        <!--Stripe.js injects the Payment Element-->
      </div>
    </form>

    <div
      v-if="includechecks"
      class="padding-vertical-15 card-check-boxes level field"
    >
      <input
        id="terms"
        v-model="acceptedTerms"

        type="checkbox"
        value="true"
        class="margin-0"
        @change="updateTerms"
      >
      <span class="custom-checkbox" />
      <div
        v-if="!isNhl"
        class="print-link"
      >
        <!-- eslint-disable vue/no-v-html -->
        <label
          for="terms"
          v-html="$t('creditCardForm.acceptedTerms')"
        />
        <!--eslint-enable-->
        <span class="print-btn fa fa-print" />

        <div :class="displayErrorClass">
          {{ displayError }}
        </div>
      </div>
      <div
        v-else
        class="print-link"
      >
        <!-- eslint-disable vue/no-v-html -->
        <label
          for="terms"
          v-html="$t('creditCardForm.acceptedTerms')"
        />
        <!--eslint-enable-->
        <span class="print-btn fa fa-print" />

        <div :class="displayErrorClass">
          {{ displayError }}
        </div>
      </div>
      
      <PrivacyCheckbox
        :nba="false"
        :is-nhl="isNhl"
        :checkout="true"
        :error="placeOrderError"
        @errorresolved="errorResolved"
        @privacyconsent="updatePrivacy"
      />
      <label 
        class="f1consent"
        for="f1consent"
      >
        <input
          id="f1consent"
          v-model="f1Consent"
          type="checkbox"
          class="f1consent"
          value="true"
          @change="$emit('update-f1', f1Consent)"
        >
        <span v-html="$t('creditCardForm.f1Consent')" />
      </label>
      <span
        v-if="isNhl"
        class="content-label"
        v-html="$t('form.nhl_privacy_policy')"
      />
    </div>

    <div v-if="coveredByCredit">
      <ZeroDollarCheckoutButton
        :buttontext="$t('paymentTerms.pay_now')"
        :accepted-terms="acceptedTerms"
        :f1-consent="f1Consent"
        @place-order="placeOrderWithCredit(null)"
        @update-terms="updateTerm"

        @update-f1="updateF1"
      />
    </div>
    <div v-else>
      <button
        id="submit"
        class="btn btn-secondary float-right"
        @click="handleSubmit($event)"
      >
        <div
          id="spinner"
          class="spinner hidden"
        />
        <span
          id="button-text"
        >Make Payment</span> <!-- need to translate -->
      </button>
    </div>

    <!-- <div id="payment-request-button"> -->
    <!-- A Stripe Element will be inserted here. -->
    <!-- </div> -->
    <div
      id="payment-message"
      class="hidden"
    />
  </div>
</template>

<script>
/* global Stripe, stripe, Turbo */
// import { watch } from 'vue'
import CartApi from '../../cart/cart_api'
import ZeroDollarCheckoutButton from '../zero_dollar_checkout_button.vue'
// import StripeJS from '../../stripe_form/stripe'
import * as Sentry from '@sentry/browser'
import PrivacyCheckbox from '../../../default/privacy_checkbox'
import { mapActions, mapMutations, mapState } from 'vuex'
export default {
  components: {
    ZeroDollarCheckoutButton,
    PrivacyCheckbox
  },

  data(){
    return {
      mountedElements: null,
      displayErrorClass: 'hidden',
      f1Consent: false, //need to figure this out
      includechecks: true,
      acceptedTerms: false,
      placeOrderError: false,
      loading: false,
      stripe: null,
      appearance: null,
      appearanceLoaded: false,
      orderLoaded: false
    }
  },

  computed: {
    ...mapState('checkout', {
      // billing: state => state.billing,
      isGuest: state => state.isGuest,
      protectGroupDecisionMade: state => state.protectGroupDecisionMade,
      roomInformation: state => state.roomInformation
    }),

    eu(){
      return this.order && this.order.in_eu
    },

    missingBillingItem(){
      let missingItems = []
      this.order.first_name ? null : missingItems.push('First name')
      this.order.last_name ? null : missingItems.push('Last name')
      this.order.phone ? null : missingItems.push('Phone')
      this.order.street_1 ? null : missingItems.push('Address')
      this.order.city ? null : missingItems.push('City')
      // this.order.state && this.billing.state != 'missing state value' ? null : missingItems.push('State')
      this.order.country ? null : missingItems.push('Country')
      this.order.email ? null : missingItems.push('Email Address')
      this.protectGroupDecisionMade ? null : missingItems.push('Refundable Booking Selection')
      // this.billing.zip_code ? null : missingItems.push('Zip Code')

      return missingItems.join(', ')
    },

    site_phone_number(){
      if(this.queriedPhone){
        return this.queriedPhone.innerText
      } else {
        return '(866) 834-8663'
      }
    },

    queriedPhone(){
      return document.getElementsByClassName('phone-numbers displayed')[0]
    },

    validToPay(){
      return this.order.email &&
      this.order.phone &&
      this.order.street_1 &&
      this.order.city &&
      this.order.country &&
      this.protectGroupDecisionMade
    },


    firstPaymentTermAmount(){
      if(this.order.zero_dollar_change_order){
        return null
      } else if (this.order.order_type === 'ChangeOrder' || this.order.order_type === 'QuoteOrder'){
        return this.order.amount_due_today
      } else {
        return this.order.payment_terms[0].amount
      }
    },
    displayErrorText() {
      return this.$t('creditCardForm.pleaseAccept')
    },
    formData() {

      return {
        // checkout: {
        accepted_terms: this.coveredByCredit ? true : this.acceptedTerms,
        // address: this.billing.address1,
        // address2: this.billing.address2,
        amount: this.firstPaymentTermAmount,
        // card_id: this.card.id,
        // city: this.billing.city,
        // company_name: this.billing.companyName,
        // country: this.billing.country,
        paid_by_credits: this.coveredByCredit,
        credits: this.creditsUsed,
        f1_consent: this.f1Consent,
        // first_name: this.billing.firstName,
        ga_id: this.gaId,
        gcl_id: window.localStorage.gclid,
        // last_name: this.billing.lastName,
        // order_id: this.order.id,
        // phone_number: this.billing.phoneNumber,
        // purchase_order_number: this.billing.purchaseOrderNumber,
        room_information: this.$store.state.checkout.roomInformation,
        // state: this.billing.state,
        // zip_code: this.billing.zipCode
      }
      // }
    },

    depositHasPackage(){
      if(this.order && this.order.deposit_item){
        return this.order.items.lengh > 1
      } else {
        return true
      }
    },

    creditsUsed(){
      if(this.order.credit_amount > 0){
        return Math.min(this.order.credit_amount, this.order.total)
      }

      return 0
    },
    clientSecret(){
      return this.$store.state.checkout.paymentIntentClientSecret
    },
    coveredByCredit() {
      return (this.order.credit_amount + 100 >= this.order.total || this.order.zero_dollar_change_order)
    },
    order(){
      return this.$store.state.order.order
    },
    appearanceAndOrderLoaded(){
      return this.appearanceLoaded && this.orderLoaded
    },
    isNhl() {
      return window.name === 'nhl'
    }
  },

  watch: {
    order(){
      if(document.getElementById('stripeElementsCheckout')){
        this.orderLoaded = true
      }
    },
    appearance(){
      if(document.getElementById('stripeElementsCheckout')){
        this.appearanceLoaded = true
      }
    },
    appearanceAndOrderLoaded(){
      this.buildStripeForm()
    }
  },

  mounted() {
    this.removeFooterMargin()
  },

  created() {
    import(`../../../${window.name}/stripe_styling`).then((data) => { this.appearance = data })
  },

  methods: {
    ...mapMutations('order', ['updateLoading']),
    ...mapActions('order', ['getBackendOrder']),
    updateTerms() {
      if (this.acceptedTerms == false) {
        this.displayError = this.displayErrorText
        this.displayErrorClass = 'card-errors'
      } else {
        this.displayError = ''
        this.displayErrorClass = 'hidden'
        // this.displayError = ''
      }

      this.$emit('update-terms', this.acceptedTerms)
    },
    buildStripeForm(){
      if(this.order && this.order.total > 0 && !this.coveredByCredit) {

        this.stripe = Stripe(window.public_token, {betas : ['blocked_card_brands_beta_2']})
        let options = {
          mode: 'payment',
          amount: Math.round(this.firstPaymentTermAmount),
          // needs to be an integer. Math.round so 100.0 becomes 100
          currency: this.order.currency.toLowerCase(),
          paymentMethodCreation: 'manual',
          fonts: this.appearance.fonts,
          appearance: this.appearance.appearance,
        }
        if (this.order.card_only_checkout) {
          const onlyCard = {paymentMethodTypes: ['card']}
          options = {...options, ...onlyCard}
        }
        if (this.order.amex_only) {
          options = {...options, ...{allowedCardBrands : ['american_express']}}
        }
        this.mountedElements = this.stripe.elements( options )
        const paymentElementOptions = {
          layout: {
            type: 'accordion',
            defaultCollapsed: false,
            radios: true,
            spacedAccordionItems: false
          },
          fields: {
            billingDetails: {
              address: {
                country: 'never',
                postalCode: 'never'
              }
            }
          }
        }

        const paymentElement = this.mountedElements.create('payment', paymentElementOptions)
        if(document.querySelector('#payment-element')){
          paymentElement.mount('#payment-element')
        }
      }
    },

    updatePrivacy(bool){
      this.$store.state.checkout.privacyConsent = bool
    },

    async handleSubmit(e) {
      e.preventDefault()
      if (this.acceptedTerms == false) {
        this.displayError = this.displayErrorText
        this.displayErrorClass = 'card-errors'
        return
      } else if (this.eu && this.$store.state.checkout.privacyConsent == false){
        this.placeOrderError = 'Please consent to the privacy policy'
        return
      }

      this.chargePaymentIntent()
    },

    errorResolved(){
      this.placeOrderError = false
    },

    async chargePaymentIntent(){
      this.updateLoading(true)
      const stripe = this.stripe
      const elements = this.mountedElements

      const {error: submitError} = await elements.submit()

      if (submitError) {
        this.handleError()
        return
      }

      // Create the PaymentMethod using the details collected by the Payment Element
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        elements,
        params: {
          billing_details: {
            name: `${this.order.first_name} ${this.order.last_name}`,
            email: this.order.email,
            phone: this.order.phone,
            address: {
              city: this.order.city,
              country: this.order.country,
              line1: this.order.street_1,
              line2: this.order.street_2,
              postal_code: this.order.zip_code || '00000',
              state: this.order.state
            }
          }
        }
      })

      if (error) {
        this.handleError()
        this.updateLoading(false)
        return
      }

      // Create the PaymentIntent

      const body = {
        paymentMethodId: paymentMethod.id
      }
      const checkout = {

        ...this.formData,
        ...body
      }

      const res = await CartApi.placeOrder({checkout})
      if(res.redirect) {
        window.location.href = res.redirect
      } else if (res.client_secret){
        this.handleSCA(res)
      } else {
        this.updateLoading(false)
        this.showMessage(res.error)
      }
    },

    confirmPayment(paymentTermId, paymentIntentId) {
      let body = JSON.stringify({ checkout: {
        f1_consent: this.f1Consent,
        payment_intent_id: paymentIntentId,
        payment_term_id: paymentTermId,
      }})

      CartApi.checkoutConfirm(body).then(order => {
        if (order.redirect) {
          Turbo.visit(order.redirect)
        } else if (order.error) {
          this.updateLoading(false)
          this.showMessage(order.error)
        } else if (order.hotel_rate_change) {
          this.updateLoading(false)
          this.displayRateChangeModal(order)
        } else {
          this.updateLoading(false)
          this.showMessage(this.$t('checkout.payment.couldNotProcess'))
        }
      })
    },
    displayRateChangeModal(order){
      this.updatedRoom = order.room
      this.rateChangeModalDisplayed = true
      this.nightlyRate = order.nightly_rate
      this.updatedItemId = order.item_id
      this.updateHotelId = order.hotel_id
      this.eventId = order.event_id
      this.paymentAttempted = order.payment_attempted
      this.getBackendOrder()
    },


    handleError(){
      this.updateLoading(false)
    },

    async placeOrderWithCredit(){
      if(this.isGuest == 'true') {
        let response = await CartApi.updateGuestUser(this.$store.state.checkout.emailAddress)
        if (response.ok) {
          this.creditCheckout()
        } else if (!response.ok) {
          this.loginErr = true
        }
      } else {
        this.creditCheckout()
      }
    },

    async handleSCA(order) {
      const {
        error,
        paymentIntent
      } = await this.stripe.handleNextAction({
        clientSecret: order.client_secret
      })


      if (error) {
        this.updateLoading(false)
        this.showMessage(error)
      } else {
        this.confirmPayment(order.payment_term_id, paymentIntent.id)
      }
    },

    creditCheckout(){
      CartApi.elementsCreditCheckout(JSON.stringify({checkout: this.formData})).then(order => {
        if (order.redirect) {
          window.location.replace(order.redirect)
        } else if (order.client_secret) {
          this.handleSCA(order)
        } else if (order.error) {
          this.errs = order.error
        } else if (order.hotel_rate_change) {
          this.displayRateChangeModal(order)
        }else {
          this.errs = this.$t('checkout.payment.couldNotProcess')
        }
      })
    },

    showMessage(messageText) {
      this.updateLoading(false)
      const messageContainer = document.querySelector('#payment-message')

      messageContainer.classList.remove('hidden')
      let shownText = messageText ? messageText : `We were unable to complete the purchase.  Please try again later or contact us at ${ this.site_phone_number }`
      if(typeof shownText === 'string'){
        messageContainer.innerHTML = shownText
      } else {
        messageContainer.innerHTML = `We were unable to complete the purchase.  Please try again later or contact us at ${ this.site_phone_number }`
        Sentry.captureMessage(`Expected a string but got ${typeof this.shownText} for ${this.order.email}`, {
          level: 'error',
          extra: { value: this.shownText, email: this.order.email }
        })
      }
    },

    removeFooterMargin() {
      // this is stupid but I don't see another way to do it since it's only this page
      let f1Footer = document.querySelector('.f1-footer')
      if (f1Footer) {
        document.querySelector('.f1-footer').style = 'margin-top: 0'
      }
    },

    // Fetches the payment intent status after payment submission
    async checkStatus() {
      const clientSecret = this.clientSecret

      if (!clientSecret) {
        return
      }

      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)

      switch (paymentIntent.status) {
      case 'succeeded':
        this.showMessage('Payment succeeded!')
        break
      case 'processing':
        this.showMessage('Your payment is processing.')
        break
      case 'requires_payment_method':
        this.showMessage('Your payment was not successful, please try again.')
        break
      default:
        this.showMessage('Something went wrong.')
        break
      }
    }
  }
}
</script>