<template>
  <div class="purchasing">
    <div class="row">
      <div class="col-sm-12">
        <hr>

        <h2>
          {{ $t('formPackage.packagePricing') }}
        </h2>
        <!-- eslint-disable vue/no-v-html -->
        <p v-html="$t('formPackage.readyToCall', { phoneLink: phoneLink })" />
        <!--eslint-enable-->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    phoneLink() {
      return `<a role="button" href="tel:${this.phoneNumber}" class="phone-numbers">${this.phoneNumber}</a>`
    },

    phoneNumber() {
      let location = document.getElementById('country-code').dataset.code
      let phoneNumber = window.phone_numbers[location] || window.phone_numbers['default']
      return phoneNumber
    }
  }
}
</script>
