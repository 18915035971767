<template>
  <section v-if="cartEditPage">
    <div class="order-item-header">
      <h3>
        {{ firstItem.event_display_name || order.event_name }}
      </h3>
    </div>
    <!-- Package Items -->
    <div
      v-for="item in order.items.filter(item => {
        return item.product_type === 'Package' || item.product_type === 'Ticket'
      })"
      :key="item.id"
      class="package-items"
    >
      <PackageOnlyElements
        :item="item"
        :showhotels="false"
        :cartdisabled="cartDisabled"
      />
    </div>

    <!-- Hotel Items -->
    <div
      v-for="item in order.items.filter(item => ['Hotel', 'Stay'].includes(item.product_type))"
      :key="item.id"
      class="hotel-items"
    >
      <HotelOnlyElements
        :item="item"
      />
    </div>

    <!-- Transportation Items -->
    <div
      v-for="item in order.items.filter(item => item.product_type === 'Transportation')"
      :key="item.id"
      class="transportation-items"
    >
      <PackageOnlyElements
        :item="item"
        :showhotels="false"
        :cartdisabled="cartDisabled"
      />
    </div>

    <div
      v-for="item in order.items.filter(item => item.product_type === 'Processing Fee')"
      :key="item.id"
      class="transportation-items"
    >
      <PackageOnlyElements
        :item="item"
        :showhotels="false"
        :cartdisabled="cartDisabled"
      />
    </div>
  </section>

  <section v-else>
    <!-- Package Items -->
    <div
      v-for="item in order.items.filter(item => {
        return item.product_type === 'Package' || item.product_type === 'Ticket'
      })"
      :key="item.id"
      class="package-items"
    >
      <div class="quote-order-item border-bottom-ccc">
        <PackageOnlyItem
          :item="item"
          :showhotels="false"
          :cartdisabled="cartDisabled"
        />
      </div>
    </div>

    <!-- Hotel Items -->
    <div
      v-for="item in order.items.filter(item => ['Hotel', 'Stay'].includes(item.product_type))"
      :key="item.id"
      class="hotel-items"
    >
      <div class="quote-order-item border-bottom-ccc">
        <HotelOnlyItem
          :item="item"
        />
      </div>
    </div>

    <!-- Transportation Items -->
    <div
      v-for="item in order.items.filter(item => item.product_type === 'Transportation')"
      :key="item.id"
      class="transportation-items"
    >
      <div class="quote-order-item border-bottom-ccc">
        <PackageOnlyItem
          :item="item"
          :showhotels="false"
          :cartdisabled="cartDisabled"
        />
      </div>
    </div>
    <div
      v-for="item in order.items.filter(item => item.product_type === 'Processing Fee')"
      :key="item.id"
      class="transportation-items"
    >
      <div class="quote-order-item border-bottom-ccc">
        <PackageOnlyItem
          :item="item"
          :showhotels="false"
          :cartdisabled="cartDisabled"
        />
      </div>
    </div>
  </section>
</template>
<script>
import PackageOnlyElements from '../../../shared/cart/order_items/elements/package_only_item'
import HotelOnlyElements from '../../../shared/cart/order_items/elements/hotel_only_item'
// import TransportationElements from '../../../shared/cart/order_items/elements/transportation_item'
import PackageOnlyItem from '../../../shared/cart/order_items/version_1/package_only_item'
import HotelOnlyItem from '../../../shared/cart/order_items/version_1/hotel_only_item'

export default {
  components: {
    PackageOnlyElements,
    HotelOnlyElements,
    // TransportationElements
    PackageOnlyItem,
    HotelOnlyItem
  },

  computed: {
    order(){
      return this.$store.state.order.order
    },
    cartDisabled(){
      return (this.order.disabled_cart)
    },
    cartEditPage(){
      return document.getElementById('cartEdit')
    },
    firstItem() {
      return this.order.items[0]
    },
  }
}
</script>