<template>
  <div class="order-summary sticky">
    <div
      class="dt-hidden flex flex-row-no-bs justify-content-between padding-top-20 padding-bottom-20 summary-container"
      @click="toggleMobile"
    >
      <div>
        <span class="expandable_header mobile-order-summary">
          {{ displayText }}
          <i class="fa fa-light fa-chevron-down" />
        </span>
      </div>

      <div class="mobile-currency-show">
        <span class="expandable_header currency">
          {{ order.currency }}
        </span>
        <span class="expandable_header order-total">
          {{ order.total_decorated }}
        </span>
      </div>
    </div>

    <div
      id="checkoutReviewOrder"
      class="column is-11"
      :class="['open', emptyCartClass, 'review-section mobileOrderSummaryContainer']"
    >
      <h2
        class="expandable_header"
      >
        {{ $t('checkout.orderSummary') }}
      </h2>
      <a 
        role="button"
        :href="cartSummaryLink"
        :class="displayEditCart"
      >
        {{ $t('checkout.editCart') }}
      </a>
      <div class="expandable_content">
        <Cart
          ref="Cart"
          :checkoutpage="true"
          @amount-update="updateStatePaymentFields"
          @updateorder="updateOrder($event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import Cart from './cart'
import { mapState } from 'vuex'

export default {
  name: 'CheckoutReview',
  components: {
    Cart
  },

  props: {
    number: {
      type: Number,
      default: 0
    },
    updateorder:{
      type: Function,
      default: null
    }
  },

  data() {
    return {
      cartHasItems: true,
      sectionName: 'review',
      sectionNext: 'payment',
      displayMobile: false
    }
  },

  computed: {
    ...mapState('checkout', {
      section: state => state.section
    }),
    emptyCartClass() {
      return (this.cartHasItems) ? '' : 'empty'
    },
    displayText(){
      return this.displayMobile ? 'Close Summary': 'Show Order Summary'
    },
    order(){
      return this.$store.state.order.order
    },
    cartSummaryLink(){
      return `/${window.locale}/cart/summary`
    },
    displayEditCart(){
      if(this.order.order_type === 'QuoteOrder' || 
      this.order.order_type === 'ChangeOrder') {
        return 'display-none'
      } else {
        return ''
      }
    }
  },

  methods: {
    updateStatePaymentFields(newFields) {
      if (newFields.itemCount > 0) {
        this.cartHasItems = true
      } else {
        this.cartHasItems = false
      }
    },
    toggleMobile(){
      if (this.displayMobile) {
        this.displayMobile = false
        gsap.to('.mobileOrderSummaryContainer', {duration: 1, height: '0', ease: 'power2.inOut'})
        gsap.to('.fa-chevron-down', {rotation: 0, x: 0, duration: 1})
      } else {
        this.displayMobile = true
        gsap.to('.mobileOrderSummaryContainer', {duration: 1, height: 'auto', ease: 'power2.inOut'})
        gsap.to('.fa-chevron-down', {rotation: -180, x: 0, duration: 1})
      }
    },
    updateOrder(order){
      this.$emit('updateorder', order)
    }
  }
}
</script>
