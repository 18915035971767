  <!-- eslint-disable vue/no-v-html -->
<template>
  <div>
    <div class="add-ons-section">
      <h2 class="order-item-header-text">
        {{ $t('cart.enhanceYourExperience') }}
      </h2>
      <div
        v-if="errors"
        class="errors"
      >
        {{ errors }}
      </div>
      <div

        class="add_ons"
        v-html="addOnsDiv"
      />
    </div>
    <div
      id="showMoreAddOns"
      class="more-add-ons"
    >
      <div
        v-if="totalAddOns > 3"
        class="load-more toggle-btn btn btn-primary"
        @click="showMoreAddOns"
      >
        <span v-if="showingAll">
          {{ $t('h4h.showLess') }}
        </span>
        <span v-else>
          {{ $t('h4h.showMore') }}
        </span>
        <span :class="['arrow-wrapper', rotatedClass]" />
      </div>
    </div>
  </div>
</template>

<script>

import CartApi from '../../cart/cart_api'
import { mapActions, mapMutations } from 'vuex'
import { trackPageView as gtmTrackPageView } from '../../../default/google_tag_manager'
import gsap from 'gsap'

export default {

  data() {
    return {
      showingAll: false,
      errors: null
    }
  },
  computed:{
    order() {
      return this.$store.state.order.order
    },
    rotatedClass(){
      return this.showingAll ? 'rotated' : ''
    },
    addOns(){
      if(this.order && this.order.items.length > 0){
        return this.order.items.map(i => i.add_ons)
      } else {
        return []
      }
    },
    buttonText(){
      return this.showingAll ? 'SHOW LESS' : 'SHOW MORE'
    },
    addOnsDiv() {
      if(this.order && this.order.items.length > 0){
        return this.order.add_on_div
      } else {
        return []
      }
    },
    totalAddOns(){
      return this.order.add_ons_length
    }
  },

  watch: {
    addOnsDiv(){
      setTimeout(() => this.ensureSelectable(), 100)
    }
  },

  mounted() {
    this.ensureSelectable()
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('cart', ['displayCart']),
    ensureSelectable(){
      Array.from(document.getElementsByClassName('add-on-container')).forEach(ele => {
        const aTag = ele.querySelector('.button-and-link a')
        if(aTag){ aTag.href = `/${window.locale}/${ele.dataset.path}` }
        const btn = ele.querySelector('.button-and-link .btn')
        if (!btn || btn.dataset.tracking) return
        btn.dataset.tracking = true
        btn.addEventListener('click', () => {
          const packageId = ele.dataset.id
          const qty = ele.dataset.qty
          this.addToCart(packageId, qty)
        })
      })
    },
    showMoreAddOns() {
      const additionalAddOns = document.querySelector('.additional-add-ons')
      this.showingAll = !this.showingAll
      if (this.showingAll) {
        const height = additionalAddOns.scrollHeight

        gsap.to(additionalAddOns, { height: height, duration: 0.5, ease: 'power2.inOut' })
      } else {
        gsap.to(additionalAddOns, { height: 0, duration: 0.5, ease: 'power2.inOut' })
      }
    },
    addToCart(packageId, qty){
      gtmTrackPageView(`https://${window.location.host}/add-on-selected`, 'Add On Selected', '/add-on-selected')
      let body = JSON.stringify({
        order_item: {
          package_id: packageId,
          quantity: qty,
        }
      })
      CartApi.createOrderItem(body).then(response => {
        if (response.id) {
          this.getBackendOrder().then( () => {
            this.displayCart()
            // this.ensureSelectable()
          })

        } else {
          this.errors = response.errors || this.$t('formPackage.error')
        }
        this.disableButton = false
      })
    }
  }
}
</script>
