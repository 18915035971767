<template>
  <div
    class="container breadcrumb-container"
    :class="windowPath === checkoutPath ? 'checkout-breadcrumb' : ''"
  >
    <ul
      v-if="isOrderLoaded"
      class="purchase-path"
    >
      <a
        class="path-status"
        role="button"
        @click="goToPackagePath()"
      >
        <span class="path-status-number">1</span>
        <span class="nav-package-text">{{ $t('h4h.yourPackage') }}</span>
      </a>
      <a
        v-if="!noHotels"
        class="path-status path-status-number-middle"
        :class="windowPath !== checkoutPath ? 'in-progress' : ''"
        role="button"
        @click="goToHotelPage()"
      >
        <span
          :class="windowPath !== checkoutPath ?
            'path-status-number-active': 'path-status-number'"
        >2</span>
        <span :class="windowPath === checkoutPath ? 'nav-package-text' : ''">
          {{ $t('h4h.chooseAccomodation') }}
        </span>
      </a>
      <a
        class="path-status checkout"
        :class="windowPath === checkoutPath ? 'in-progress' : ''"
        role="button"
        @click="goToCheckoutPage()"
      >
        <span
          :class="windowPath === checkoutPath ?
            'path-status-number-active': 'path-status-number'"
        >{{ noHotels ? '2' : '3' }}</span>
        <span :class="windowPath !== checkoutPath ? 'nav-package-text' : ''">
          {{ $t('h4h.checkoutStep') }}
        </span>
      </a>
    </ul>
  </div>
</template>

<script>
/* global locale */

export default {
  data() {
    return {
      packageId: null,
      orderItemId: null,
      checkoutPath: '/cart/checkout',
      loaded: false,
      noHotels: false
    }
  },

  computed: {
    packagePath() {
      return `/${locale}/packages/${this.packageId}/path`
    },
    hotelPath() {
      return `/${locale}/cart/items/${this.orderItemId}/hotels`
    },
    windowPath() {
      let path = window.location.pathname
      let index = path.lastIndexOf('/', path.lastIndexOf('/')-1)
      return path.substring(index)
    },
    isOrderLoaded() {
      return this.loaded
    },
    order(){
      return this.$store.state.order.order
    }
  },

  watch: {
    order(){
      this.setOrder()
    }
  },

  methods: {
    async setOrder() {
      let last = this.order.items.length - 1

      this.packageId = this.order.items[last].package_id
      this.orderItemId = this.order.items[last].id
      this.noHotels = this.order.event_hotel_behavior == 'no_hotels' ? true : false
      this.loaded = true
    },
    goToPackagePath() {
      const packageId = this.packageId
      window.location.href = `/${locale}/packages/${packageId}/path`
    },
    goToCheckoutPage() {
      if ( this.windowPath !== this.checkoutPath) {
        window.location.href = `/${locale}` + this.checkoutPath
      }
    },
    goToHotelPage(){
      if(this.windowPath === this.checkoutPath){
        window.location.href = this.hotelPath
      }
    }
  }
}
</script>
