import FetchCall from  './local_fetch_call'

if (document.getElementById('personalizedBuyerPage')){
  let ORDER_ID = undefined
  let BUYER_PAGE_ID = undefined

  const params = window.location.href.split('?')[1]
  if(params){
    ORDER_ID = params.split('-')[0]
    BUYER_PAGE_ID = params.split('-')[1]
  }

  if(ORDER_ID && BUYER_PAGE_ID) {

    hidePickups()
    const body = JSON.stringify({
      order_id: ORDER_ID,
      buyer_page_id: BUYER_PAGE_ID
    })
    FetchCall.fetchWithoutLocale(`/oa/orders/${ORDER_ID}/buyers_page_personalizers`, 'POST', body).then( resp =>{
      if(resp.normal_page){
        showPickups()
      }else {
        if(resp.pickup_requested){
          showPickups()
          hideDeliveries()
        }
        if(resp.packages.length > 0){
          showOrderTable(resp.packages)
        }
        if(resp.hotels.length > 0 && resp.hotels.find( hotel => hotel.active)){
          showHotelTable(resp.hotels)
        } else {
          hideHotelSection()
        }
        if(resp.transfers.length > 0 && resp.hotels.length > 0 && resp.hotels.find( hotel => hotel.active)){
          showTransferTable(resp.hotels)
        } else {
          hideTransferSection()
        }
      }
    })
  }
}


function hideHotelSection() {
  if(document.getElementById('hotelSection')){
    document.getElementById('hotelSection').classList.add('hidden')
  }
}

function hideTransferSection() {
  if(document.getElementById('transferSection')){
    document.getElementById('transferSection').classList.add('hidden')
  }
}

function showTransferTable(hotels) {
  hotels.forEach(hotel => {

    document.getElementById('buyerPageTransportationSelect').classList.add('hidden')

    let filterTerm = hotel.id

    $('.display-transportation').each(function(){

      let domHotel, txtValue, hotelName

      domHotel = $(this)

      hotelName = domHotel.children('h5')

      if(hotelName){
        txtValue = hotelName.text()

        filterData(txtValue, domHotel, filterTerm.toString())
      }
    })
  })
}

function showHotelTable(hotels) {
  hotels.forEach(hotel => {

    document.getElementById('buyerPageHotelSelect').classList.add('hidden')

    let filterTerm = hotel.id

    $('.display-hotel').each(function(){

      let domHotel, txtValue, hotelName

      domHotel = $(this)

      hotelName = domHotel.children('h5')

      if(hotelName){
        txtValue = hotelName.text()

        filterData(txtValue, domHotel, filterTerm.toString())
      }
    })
  })
}

function showOrderTable(packages) {
  packages.forEach(pack => {
    const filterTerm = pack.sf_package_id.toUpperCase()

    if(filterTerm.length){
      const parentOfTable = $('#productTable').parent()[0]
      updateTableHeader(parentOfTable, pack)
      // clone original table to make updates
      let clone = $('#productTable').clone()[0]
      clone.classList.add('product-table')
      clone.id = `productTable-${filterTerm}`
      clone.style.display = 'flex'
      parentOfTable.append(clone)
      $(`#productTable-${filterTerm}`).show()
      $(`#productTable-${filterTerm}`).children().each(function(){
        var txtValue, row, td
        row = $(this)
        if(row[0].className.includes('headline_row')){
          let nodeArray = Array.from(row[0].childNodes)
          let arrow = nodeArray.find( node => {
            if(!node.className){return}

            return node.className.includes('arrow')
          })
          if(arrow){
            let old_href = arrow.firstElementChild.getAttribute('href')
            let new_href = old_href + '-' + filterTerm
            arrow.firstElementChild.setAttribute('href', new_href)
            let oldAria = arrow.firstElementChild.getAttribute('aria-controls')
            arrow.firstElementChild.setAttribute('aria-controls', oldAria + '-' + filterTerm)
          }
        } else if (row[0].className.includes('details_row')){
          let old_id = row[0].lastElementChild.firstElementChild.getAttribute('id')
          row[0].lastElementChild.firstElementChild.setAttribute('id', old_id + '-' + filterTerm)
        }
        row.show()
        // td = row.find('td:eq(0)')
        td = row.find('span[hidden]')
        if(td.length){
          txtValue = td.text()
          filterData(txtValue, row, filterTerm)
        }
      })
    }
  })
}

function updateTableHeader(parentOfTable, pack) {
  document.getElementById('buyerPageProductSelect').classList.add('hidden')
  let header = document.createElement('h4')
  let node = document.createTextNode(pack.display_name)

  header.appendChild(node)

  parentOfTable.append(header)
}

function filterData(txtValue, element, filterTerm) {
  if (filterTerm.length && txtValue.toUpperCase().indexOf(filterTerm) > -1) {
    element.show()
  } else {
    element.hide()
  }
}


function hidePickups() {
  let pickups = Array.from(document.querySelectorAll('[data-show=\'pickup\']'))

  pickups.forEach( (pickup) =>{
    pickup.classList.add('hidden')
  })
}

function showPickups() {
  let pickups = Array.from(document.querySelectorAll('[data-show=\'pickup\']'))

  pickups.forEach( (pickup) =>{
    pickup.classList.remove('hidden')
  })
}

function hideDeliveries() {
  let deliveries = Array.from(document.querySelectorAll('[data-show=\'delivery\']'))

  deliveries.forEach( (delivery) =>{
    delivery.classList.add('hidden')
  })
}
